// Imports
import {gsap} from "gsap";

class VimeoEmbeds {
    constructor() {
        this.players = {};
        this.embeds = document.querySelectorAll('.vimeo-embed');
        this.playButtons = document.querySelectorAll('[data-vimeo-play]');
    }

    init() {
        this.embeds.forEach((embed) => {
            const videoId = embed.getAttribute('data-video-id');
            this.players[videoId] = new Vimeo.Player(embed);
        });

        this.playButtons.forEach((button) => {
            const playerId = button.getAttribute('data-vimeo-play');

            button.addEventListener('click', () => {
                gsap.to(button, {
                    duration: 0.25,
                    alpha: 0,
                    display: 'none',
                });

                this.players[playerId].play();
            });
        });
    }

    loadAPI() {
        const apiScript = document.createElement('script');
        apiScript.src = "https://player.vimeo.com/api/player.js";
        document.head.appendChild(apiScript);

        apiScript.addEventListener('load', () => {
            this.init();
        });
    }
}

export default new VimeoEmbeds();
