// Imports
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';

class MasonryFiltering {
    constructor() {
        this.selectedClass = '_active';
        this.buttons = document.querySelectorAll('.filter-btn');
        this.selectedButton = document.querySelector('.filter-btn._active');
        this.relayoutButtons = document.querySelectorAll('.masonry-relayout-btn');

        const container = document.querySelector('.masonry-filtering');

        if (container) {
            document.addEventListener('DOMContentLoaded', () => {
                // Init Isotope
                let iso = new Isotope(container, {
                    itemSelector: '.filter-item',
                    percentPosition: true,
                    masonry: {
                        // use element for option
                        columnWidth: '.filter-sizer'
                    }
                });

                // Bind filter button click
                this.buttons.forEach(btn => {
                    btn.addEventListener('click', (event) => {
                        if (btn.classList.contains(this.selectedClass)) {
                            return;
                        }

                        // Update selected button
                        this.selectedButton.classList.remove(this.selectedClass);
                        btn.classList.add(this.selectedClass);
                        this.selectedButton = btn;

                        // Filter
                        const {filter} = event.target.dataset;
                        iso.arrange({filter});
                    });
                });

                // Bind reload button click
                this.relayoutButtons.forEach(btn => {
                    btn.addEventListener('click', () => {
                        this.relayout(iso);
                    });
                });

                // If Alpine hasn't been initialized yet
                if (typeof Alpine === 'undefined') {
                    document.addEventListener('alpine:init', () => {
                        this.relayout(iso);
                    });
                }

                // Layout Isotope after each image loads
                imagesLoaded(container).on('progress', (image) => {
                    console.log('Image loaded');
                    console.log(image);
                    this.relayout(iso);
                });
            });
        }
    }

    relayout(iso) {
        setTimeout(() => {
            iso.layout();
        }, 30);
    }
}

export default new MasonryFiltering();
