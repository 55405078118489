/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

import LazyLoad from 'vanilla-lazyload';
import reframe from 'reframe.js';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Alpine.start();
window.Alpine = Alpine;

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import NavHeight from '@js/helpers/NavHeight';
import VhCalc from '@js/helpers/ViewHeightCalc';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import Autocomplete from '@js/modules/map/Autocomplete';
import CampaignStateUpdater from '@js/modules/CampaignStateUpdater';
import Carousels from '@js/modules/Carousels';
import Characters from '@js/modules/Characters';
import CookieMessage from '@js/modules/CookieMessage';
import Counter from '@js/modules/Counter';
import MaterialCarousel from '@js/modules/MaterialCarousel';
import Salon from '@js/modules/Salon';
import Sharer from '@js/modules/Sharer';
import Marquee from '@js/modules/Marquee';
import MasonryFiltering from '@js/modules/MasonryFiltering';
import Navigation from '@js/modules/Navigation';
import Newsletter from '@js/modules/Newsletter';
import ScrollTo from '@js/modules/ScrollTo';
import Subnav from '@js/modules/Subnav';
import Switchers from '@js/modules/Switchers';
import Timeline from '@js/modules/Timeline';
import VimeoEmbeds from '@js/modules/VimeoEmbeds';
import YoutubeEmbeds from '@js/modules/YoutubeEmbeds';

window.SUSTAINABLESALONS =
    window.SUSTAINABLESALONS ||
    new (function SUSTAINABLESALONS() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            VhCalc: VhCalc,
            NavHeight: NavHeight,
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Appear: Appear,
            AppearParent: AppearParent,
            Autocomplete: Autocomplete,
            ScrollTo: ScrollTo,
        };


        // Window Load
        window.addEventListener('load', e => {
            Switchers.start();
        });


        document.addEventListener('DOMContentLoaded', () => {
            /**
             * Lazy load
             */
            window.lazyLoadInstance = new LazyLoad({
                threshold: 500
            });
            window.addEventListener(
                "LazyLoad::Initialized",
                function (event) {
                    window.lazyLoadInstance = event.detail.instance;
                },
                false
            );

            /**
             * Reframe
             */
            reframe('.reframe');

            /**
             * Sharer
             */
            Sharer.init();

            /**
             * Current year
             */
            // Return today's date and time
            const currentTime = new Date();
            // Returns the year (four digits)
            const currentYear = currentTime.getFullYear();

            // Output year to all elements
            const currentYearEls = document.querySelectorAll('.current-year');
            currentYearEls.forEach(el => {
                el.innerHTML = currentYear;
            });

            /*
             * Video embeds
             */
            VimeoEmbeds.loadAPI();
            YoutubeEmbeds.loadAPI();


            /*
                Initialize autocomplete
             */
            Autocomplete.activateSalonLocator();

            /*
                Carousels
             */
            Carousels.setup();

            /*
                Salons
             */
            Salon.currentDay();
            Salon.related();
        });

    })();




