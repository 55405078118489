const CAMPAIGN_END_ATTR = 'data-campaign-end';

class CampaignStateUpdater {
    constructor() {
        this.updateCampaignStates();
    }

    updateCampaignStates() {
        const campaignEndEls = document.querySelectorAll(`[${CAMPAIGN_END_ATTR}]`);
        campaignEndEls.forEach(el => {
            const attributeValues = el.getAttribute(CAMPAIGN_END_ATTR)?.split(',') || [];
            const [date, archivedText = 'Archived', activeText = 'Active'] = attributeValues;

            if (date) {
                const endDate = new Date(date);
                if (isNaN(endDate)) {
                    console.warn(`Invalid date value '${date}' for element ${el}`);
                } else {
                    const now = new Date();
                    const endDateWithoutTime = new Date(
                        endDate.getFullYear(),
                        endDate.getMonth(),
                        endDate.getDate()
                    );
                    const nowWithoutTime = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate()
                    );
                    if (endDateWithoutTime < nowWithoutTime) {
                        el.textContent = archivedText;
                    } else if (endDateWithoutTime.getTime() === nowWithoutTime.getTime()) {
                        el.textContent = activeText;
                    } else {
                        el.textContent = activeText;
                    }
                }
            }
        });
    }

    updateCampaignStateForElement(id) {
        const el = document.getElementById(id);
        if (el) {
            this.updateCampaignStates(el);
        } else {
            console.warn(`Element with ID '${id}' not found`);
        }
    }
}

export default new CampaignStateUpdater();
