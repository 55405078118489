// Imports
import { CountUp } from 'countup.js';

// Project imports
import env from '@js/utils/env';

class Counter {
    constructor() {
        this.attribute = 'data-countup';

        const easingFn = (t, b, c, d) => {
            const ts = (t /= d) * t;
            const tc = ts * t;
            return b + c * (tc + -3 * ts + 3 * t);
        };

        const countupEls = document.querySelectorAll(`[${this.attribute}]`);

        countupEls.forEach((el) => {
            const [value, prefix = '', suffix = ''] = el.getAttribute(this.attribute).split(',');

            const options = {
                easingFn,
                duration: env.reducedMotion ? 0 : 2,
                enableScrollSpy: true,
                scrollSpyDelay: 100,
                scrollSpyOnce: true,
                prefix,
                suffix,
            };

            const countupEl = new CountUp(el, value, options);

            if (countupEl.error) {
                console.error(countupEl.error);
            }
            // If `enableScrollSpy` is disabled calling `countupEl.start()` would be required
            // Place this in an else statement if no errors.
        });
    }
}

export default new Counter();
