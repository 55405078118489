// Imports
import Headroom from "headroom.js";
import enquire from "enquire.js";

class Subnav {
    constructor() {
        this.start();
    }
    start() {
        // Get the subnav
        const subNav = document.querySelector('.sub-nav');

        if (subNav) {
            // Active anchor
            this.activateAnchor(subNav);

            // Headroom
            this.headroomSubnav(subNav);
        }
    }

    headroomSubnav(subNav) {
        const hero = document.querySelector('.hero');
        let offsetDown = hero ? hero.offsetHeight * 1.2 : window.innerHeight * 1.2;

        const headroom = new Headroom(subNav, {
            offset: {
                up: 100,
                down: offsetDown
            },
        });

        enquire.register('screen and (max-width:767px)', {
            match : headroom.init.bind(headroom),
            unmatch : headroom.destroy.bind(headroom)
        });
    }

    detectSticky(element, threshold) {
        let isSticky = false;

        const handleSticky = () => {
            const isStickyNow = element.getBoundingClientRect().top <= threshold;

            if (isStickyNow !== isSticky) {
                isSticky = isStickyNow;

                if (isSticky) {
                    element.classList.add('is-sticky');
                } else {
                    element.classList.remove('is-sticky');
                }
            }
        };

        window.addEventListener('resize', handleSticky);
        handleSticky();
        window.addEventListener('scroll', handleSticky);
    }

    activateAnchor(subNav) {
        const sections = document.querySelectorAll('.content-section');
        const anchors = subNav.querySelector('.sub-nav-desktop').querySelectorAll('a');
        const subNavMobileBtn = subNav.querySelector('.dropdown-btn');
        let scrollPaddingTop = 0;

        const activate = (element) => {
            anchors.forEach((anchor) => {
                anchor.classList.remove('active');

                if (anchor.getAttribute('href') === `#${element.id}`) {
                    anchor.classList.add('active');

                    if (subNavMobileBtn) {
                        subNavMobileBtn.querySelector('.dropdown-btn-text').innerHTML = anchor.innerHTML.trim();
                    }
                }
            });
        };

        const handleResize = () => {
            const element = document.documentElement;
            const styles = window.getComputedStyle(element);

            scrollPaddingTop = parseFloat(styles.getPropertyValue('scroll-padding-top'));
        };

        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;

                if (
                    scrollPosition >= sectionTop - scrollPaddingTop &&
                    scrollPosition < sectionTop + sectionHeight - scrollPaddingTop
                ) {
                    activate(section);
                }
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        window.addEventListener('scroll', handleScroll);
    }
}

export default new Subnav();
