/*
 * Example usage:
 * <button data-tracking='{"category": "Button", "action": "Click", "label": "My Button"}'>Track Button</button>
 *
 * In this example, when the button is clicked, the event data { "category": "Button", "action": "Click", "label": "My Button" } will be sent to Google Analytics using the trackEvent function.
 * Remember to customise the values within the data-tracking attribute to suit your specific tracking needs.
 */

class Tracking {
    constructor() {

    }

    setup() {
        const trackingAttr = 'data-tracking';
        const trackedElements = document.querySelectorAll(`[${trackingAttr}]`);

        trackedElements.forEach(element => {
            element.addEventListener('click', this.handleClick.bind(this, trackingAttr));
        });
    }

    handleClick(trackingAttr, event) {
        const target = event.target;

        const trackingData = JSON.parse(target.getAttribute(trackingAttr));
        const { category, action, label } = trackingData;

        // Perform tracking action
        this.trackEvent(category, action, label);
    }

    trackEvent(category, action, label) {
        // Check if gtag is defined
        if (typeof gtag !== 'undefined') {
            // Send event data to Google Analytics 4
            gtag('event', action, {
                event_category: category,
                event_label: label
            });
        }
        else {
            // Handle the case when gtag is not defined
            console.warn('gtag is not defined. Make sure the Google Analytics script is properly loaded.');
        }
    }
}

export default new Tracking();
