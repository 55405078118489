// Imports
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

class Carousels {
    constructor() {

    }

    setup() {
        this.carousels = {};
        this.carouselPlugins = {};

        const carouselEls = document.querySelectorAll('.carousel');

        carouselEls.forEach((carousel, i) => {
            const carouselAutoscroll = carousel.classList.contains('_autoscroll');
            const carouselFullwidth = carousel.classList.contains('_full-width');
            const customConfig = carousel.getAttribute('data-carousel-settings');
            const splideConfig = carousel.getAttribute('data-splide');
            let carouselAdditionalConfig = {};
            let carouselConfig = {
                drag: true,
                gap: '24px',
                type: 'loop'
            };
            let carouselPlugins = {};

            // Dont override splide config
            if (!splideConfig) {
                if (customConfig !== null) {
                    carouselAdditionalConfig = JSON.parse(customConfig);

                    if (carouselAutoscroll) {
                        carouselAdditionalConfig.drag = 'free';
                    }
                } else {
                    // Test if autoscroll
                    if (carouselAutoscroll) {
                        carouselAdditionalConfig = {
                            autoWidth: true,
                            arrows: false,
                            drag: 'free',
                            pagination: false,
                            autoScroll: {
                                autoStart: true,
                                pauseOnHover: true,
                                speed: .7,
                            },
                            breakpoints: {
                                1200: {
                                    autoWidth: false,
                                    padding: '10%',
                                    perMove: 2,
                                    perPage: 1,
                                },
                                768: {
                                    autoWidth: false,
                                    gap: '8px',
                                    padding: '15%',
                                    perMove: 1,
                                    perPage: 1,
                                },
                            }
                        };
                    } else {
                        // Full width slide
                        if (carouselFullwidth) {
                            carouselAdditionalConfig = {
                                padding: 0,
                                perMove: 1,
                                perPage: 1
                            };
                        } else {
                            carouselAdditionalConfig = {
                                padding: '5%',
                                perMove: 1,
                                perPage: 4,
                                breakpoints: {
                                    1200: {
                                        padding: '10%',
                                        perMove: 2,
                                        perPage: 1,
                                    },
                                    768: {
                                        padding: '15%',
                                        perMove: 1,
                                        perPage: 1,
                                    },
                                }
                            };
                        }
                    }
                }
            }

            if (carouselAutoscroll) {
                // Apply plugin
                carouselPlugins = {AutoScroll};
            }

            // Update config to include the additional config
            carouselConfig = {...carouselConfig, ...carouselAdditionalConfig};

            this.carousels[i] = new Splide(carousel, carouselConfig);
            this.carousels[i].mount(carouselPlugins);
        });
    }
}

export default new Carousels();
