// Project helpers
import { getSessionInfo } from '@js/helpers/Craft';

class Newsletter {
    constructor() {
        const newsletterEls = document.querySelectorAll(".newsletter-form");

        newsletterEls.forEach((newsletter) => {
            newsletter.onsubmit = (e) => {
                e.preventDefault();

                const newsletterMessage = newsletter.querySelector(".newsletter-message");

                if (newsletterMessage) {
                    newsletterMessage.remove();
                }

                getSessionInfo()
                    .then(session => {
                        console.log({session});
                        const params = new FormData(newsletter);

                        fetch('/mailchimp/send', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'X-CSRF-Token': session.csrfTokenValue,
                                'X-Requested-With': 'XMLHttpRequest',
                            },
                            body: params,
                        })
                            .then((response) => {
                                console.log({response});
                                if (!response.ok) {
                                    throw new Error("Network response was not ok");
                                }
                                return response.json();
                            })
                            .then((data) => {
                                let message = data.msg;

                                if (data.success) {
                                    console.log(message);

                                    // Reset form
                                    newsletter.reset();

                                    // Hide newsletter
                                    document.querySelector('.newsletter-form-fields').classList.add('hidden');
                                } else {
                                    console.warn(message);
                                }

                                if (message === "Mailchimp error: Member Exists") {
                                    message = "Email already subscribed";
                                }

                                // Replace `Mailchimp error:`
                                message = message.replace("Mailchimp error:", "Error:");

                                // Output message
                                this._message(newsletter,data.success ? 'success' : 'error', message);
                            })
                            .catch((error) => {
                                console.error(error);

                                // Output message
                                this._message(newsletter, 'error', 'Something went wrong, please try again');
                            });
                    });
            };
        });
    }

    _message(el, type, message) {
        if (el) {
            const baseClass = ["newsletter-message", "border-2", "font-semibold", "mt-2", "p-2", "rounded-lg", "text-center", "text-sm"];
            const successClass = ["border-teal-dark", "text-teal-dark"];
            const errorClass = ["border-red-600", "text-red-500"];

            // Merge classes arrays
            const messageClass = type === 'success' ? baseClass.concat(successClass) : baseClass.concat(errorClass);

            // Put together message element
            const messageEl = document.createElement("div");
            messageEl.classList.add(...messageClass);
            messageEl.innerHTML = message;

            // Add message element to the
            el.insertAdjacentElement("afterbegin", messageEl);
        }
    }
}

export default new Newsletter();
