// Imports
import SwipeListener from 'swipe-listener';

const HTML_CLASSLIST = document.documentElement.classList;
const eventsMinDistance = 90;

class Timeline {
    constructor() {
        this.timelineAttribute = 'data-horizontal-timeline';

        // Timeline elements
        const timelineElements = document.querySelectorAll(`[${this.timelineAttribute}]`);

        timelineElements.forEach(timeline => {
            this._setup(timeline);
        });
    }
    _setup(timeline) {
        var timelineComponents = {};

        // Settings
        const settings = timeline.getAttribute(this.timelineAttribute);
        const [equalDateSpacing = false, enableSwiping = true] = settings.split(', ');

        // Cache timeline components
        timelineComponents['timelineWrapper'] = timeline.querySelector('.events-wrapper');
        timelineComponents['eventsWrapper'] = timelineComponents['timelineWrapper'].querySelector('.events');
        timelineComponents['fillingLine'] = timelineComponents['eventsWrapper'].querySelector('.filling-line');
        timelineComponents['timelineEvents'] = timelineComponents['eventsWrapper'].querySelectorAll('button');
        timelineComponents['timelineDates'] = parseDate(timelineComponents['timelineEvents']);
        timelineComponents['eventsMinLapse'] = minLapse(timelineComponents['timelineDates']);
        timelineComponents['timelineNavigation'] = timeline.querySelector('.timeline-navigation');
        timelineComponents['eventsContent'] = timeline.querySelector('.events-content');

        // Assign a left position to the single events along the timeline
        setDatePosition(timelineComponents, eventsMinDistance);
        // Assign a width to the timeline
        var timelineTotWidth = setTimelineWidth(timelineComponents, eventsMinDistance);

        // The timeline has been initialised - Show it
        timeline.classList.add('loaded');

        // Detect click on the next arrow
        timelineComponents['timelineNavigation'].querySelector('.next').addEventListener('click', (e) => {
            e.preventDefault();
            updateSlide(timelineComponents, timelineTotWidth, 'next');
        });
        // Detect click on the prev arrow
        timelineComponents['timelineNavigation'].querySelector('.prev').addEventListener('click', (e) => {
            e.preventDefault();
            updateSlide(timelineComponents, timelineTotWidth, 'prev');
        });

        // Detect click on a single event - show new event content
        for (var i = 0; i < timelineComponents['timelineEvents'].length; i++) {
            timelineComponents['timelineEvents'][i].addEventListener('click', function(event) {
                event.preventDefault();

                // Do nothing if current button
                if (event.target.classList.contains('selected')) {
                    return;
                }

                for (var j = 0; j < timelineComponents['timelineEvents'].length; j++) {
                    timelineComponents['timelineEvents'][j].classList.remove('selected');
                }

                this.classList.add('selected');

                updateOlderEvents(this);
                updateFilling(this, timelineComponents['fillingLine'], timelineTotWidth);
                updateVisibleContent(this, timelineComponents['eventsContent']);
            });
        }

        // On swipe, show next/prev event content
        if (enableSwiping) {
            var swipeListener = SwipeListener(timelineComponents['eventsContent']);
            timelineComponents['eventsContent'].addEventListener('swipe', (e) => {
                var directions = e.detail.directions;

                if (directions.left) {
                    showNewContent(timelineComponents, timelineTotWidth, 'next');
                }

                if (directions.right) {
                    showNewContent(timelineComponents, timelineTotWidth, 'prev');
                }
            });
        }

        // Keyboard navigation
        document.addEventListener('keyup', (event) => {
            if (event.which == '37' && elementInViewport(timeline)) {
                showNewContent(timelineComponents, timelineTotWidth, 'prev');
            }
            else if (event.which == '39' && elementInViewport(timeline)) {
                showNewContent(timelineComponents, timelineTotWidth, 'next');
            }
        });

        function updateSlide(timelineComponents, timelineTotWidth, string) {
            // Retrieve translateX value of timelineComponents['eventsWrapper']
            var translateValue = getTranslateValue(timelineComponents['eventsWrapper']),
                wrapperWidth = parseFloat(getComputedStyle(timelineComponents['timelineWrapper']).width);
            // Translate the timeline to the left('next')/right('prev')
            if (string == 'next') {
                translateTimeline(timelineComponents, translateValue - wrapperWidth + eventsMinDistance, wrapperWidth - timelineTotWidth);
            } else {
                translateTimeline(timelineComponents, translateValue + wrapperWidth - eventsMinDistance);
            }
        }

        function showNewContent(timelineComponents, timelineTotWidth, string) {
            // Go from one event to the next/previous one
            var visibleContent =  timelineComponents['eventsContent'].querySelector('.selected'),
                newContent = ( string == 'next' ) ? visibleContent.nextElementSibling : visibleContent.previousElementSibling;

            if ( newContent !== null ) { //if there's a next/prev event - show it
                var selectedDate = timelineComponents['eventsWrapper'].querySelector('.selected'),
                    newEvent = ( string == 'next' ) ? selectedDate.parentNode.nextElementSibling.querySelector('button') : selectedDate.parentNode.previousElementSibling.querySelector('button');

                updateFilling(newEvent, timelineComponents['fillingLine'], timelineTotWidth);
                updateVisibleContent(newEvent, timelineComponents['eventsContent']);
                newEvent.classList.add('selected');
                selectedDate.classList.remove('selected');
                updateOlderEvents(newEvent);
                updateTimelinePosition(string, newEvent, timelineComponents, timelineTotWidth);
            }
        }

        function updateTimelinePosition(string, event, timelineComponents, timelineTotWidth) {
            // Translate timeline to the left/right according to the position of the selected event
            var eventStyle = window.getComputedStyle(event),
                eventLeft = Number(eventStyle.getPropertyValue("left").replace('px', '')),
                timelineWidth = Number(getComputedStyle(timelineComponents['timelineWrapper']).getPropertyValue('width').replace('px', '')),
                timelineTranslate = getTranslateValue(timelineComponents['eventsWrapper']);

            if ((string === 'next' && eventLeft > timelineWidth - timelineTranslate) || (string === 'prev' && eventLeft < -timelineTranslate)) {
                translateTimeline(timelineComponents, -eventLeft + timelineWidth / 2, timelineWidth - timelineTotWidth);
            }
        }

        function translateTimeline(timelineComponents, value, totWidth) {
            var eventsWrapper = timelineComponents['eventsWrapper'];
            value = (value > 0) ? 0 : value; // only negative translate value
            value = (!(typeof totWidth === 'undefined') && value < totWidth) ? totWidth : value; // do not translate more than timeline width
            setTransformValue(eventsWrapper, 'translateX', value + 'px');
            //  Update navigation arrows visibility
            if (value === 0) {
                timelineComponents['timelineNavigation'].querySelector('.prev').classList.add('inactive');
                timelineComponents['timelineNavigation'].querySelector('.prev').setAttribute('disabled', '');
            } else {
                timelineComponents['timelineNavigation'].querySelector('.prev').classList.remove('inactive');
                timelineComponents['timelineNavigation'].querySelector('.prev').removeAttribute('disabled');
            }
            if (value === totWidth) {
                timelineComponents['timelineNavigation'].querySelector('.next').classList.add('inactive');
                timelineComponents['timelineNavigation'].querySelector('.next').setAttribute('disabled', '');
            } else {
                timelineComponents['timelineNavigation'].querySelector('.next').classList.remove('inactive');
                timelineComponents['timelineNavigation'].querySelector('.next').removeAttribute('disabled');
            }
        }

        function updateFilling(selectedEvent, filling, totWidth) {
            // Change .filling-line length according to the selected event
            var eventStyle = window.getComputedStyle(selectedEvent),
                eventLeft = eventStyle.getPropertyValue("left"),
                eventWidth = eventStyle.getPropertyValue("width");

            eventLeft = Number(eventLeft.replace('px', '')) + Number(eventWidth.replace('px', '')) / 2;

            var scaleValue = eventLeft / totWidth;

            setTransformValue(filling, 'scaleX', scaleValue);
        }

        function setDatePosition(timelineComponents, min) {
            if (equalDateSpacing) {
                for (var i = 0; i < timelineComponents['timelineDates'].length; i++) {
                    var distanceNorm = i * min + min;

                    timelineComponents['timelineEvents'][i].style.left = distanceNorm + 'px';
                }
            }
            else {
                for (var i = 0; i < timelineComponents['timelineDates'].length; i++) {
                    var distance = daydiff(timelineComponents['timelineDates'][0], timelineComponents['timelineDates'][i]),
                        distanceNorm = Math.round(distance / timelineComponents['eventsMinLapse']) + 2;

                    timelineComponents['timelineEvents'][i].style.left = distanceNorm * min + 'px';
                }
            }
        }

        function setTimelineWidth(timelineComponents, width) {
            if (equalDateSpacing) {
                var numEvents = timelineComponents['timelineEvents'].length,
                    totalWidth = (numEvents * width) + (width * 1.5);

                timelineComponents['eventsWrapper'].style.width = totalWidth + 'px';

                updateFilling(timelineComponents['timelineEvents'][0], timelineComponents['fillingLine'], totalWidth);

                return totalWidth;
            }
            else {
                var timeSpan = daydiff(timelineComponents['timelineDates'][0], timelineComponents['timelineDates'][timelineComponents['timelineDates'].length - 1]),
                    timeSpanNorm = timeSpan / timelineComponents['eventsMinLapse'],
                    timeSpanNorm = Math.round(timeSpanNorm) + 4,
                    totalWidth = timeSpanNorm * width;

                timelineComponents['eventsWrapper'].style.width = totalWidth + 'px';

                updateFilling(timelineComponents['timelineEvents'][0], timelineComponents['fillingLine'], totalWidth);

                return totalWidth;
            }
        }

        function updateVisibleContent(event, eventsContent) {
            var eventDate = event.getAttribute('data-date');
            var visibleContent = eventsContent.querySelector('.selected');
            var selectedContent = eventsContent.querySelector('[data-date="'+ eventDate +'"]');
            var selectedContentHeight = selectedContent.offsetHeight;

            var classEnetering, classLeaving;
            if (selectedContent.compareDocumentPosition(visibleContent) & Node.DOCUMENT_POSITION_FOLLOWING) {
                classEnetering = 'selected enter-left';
                classLeaving = 'leave-right';
            } else {
                classEnetering = 'selected enter-right';
                classLeaving = 'leave-left';
            }

            selectedContent.className = classEnetering;
            visibleContent.className = classLeaving;

            visibleContent.addEventListener('webkitAnimationEnd', onAnimationEnd);
            visibleContent.addEventListener('oanimationend', onAnimationEnd);
            visibleContent.addEventListener('msAnimationEnd', onAnimationEnd);
            visibleContent.addEventListener('animationend', onAnimationEnd);

            function onAnimationEnd() {
                visibleContent.className = visibleContent.className.replace(/leave-right|leave-left/, '');
                selectedContent.className = selectedContent.className.replace(/enter-left|enter-right/, '');
                visibleContent.removeEventListener('webkitAnimationEnd', onAnimationEnd);
                visibleContent.removeEventListener('oanimationend', onAnimationEnd);
                visibleContent.removeEventListener('msAnimationEnd', onAnimationEnd);
                visibleContent.removeEventListener('animationend', onAnimationEnd);
            }

            eventsContent.style.height = selectedContentHeight + 'px';
        }

        function updateOlderEvents(event) {
            let parentLi = event.parentNode;
            let previousLis = [];
            while ((parentLi = parentLi.previousElementSibling)) {
                previousLis.push(parentLi);
            }
            previousLis.forEach((li) => {
                Array.from(li.querySelectorAll('button')).forEach((anchor) => {
                    anchor.classList.add('older-event');
                });
            });

            let nextLis = [];
            parentLi = event.parentNode;
            while ((parentLi = parentLi.nextElementSibling)) {
                nextLis.push(parentLi);
            }
            nextLis.forEach((li) => {
                Array.from(li.querySelectorAll('button')).forEach((anchor) => {
                    anchor.classList.remove('older-event');
                });
            });
        }

        function getTranslateValue(timeline) {
            var timelineStyle = window.getComputedStyle(timeline);

            var timelineTranslate = timelineStyle.getPropertyValue('-webkit-transform') ||
                timelineStyle.getPropertyValue('-moz-transform') ||
                timelineStyle.getPropertyValue('-ms-transform') ||
                timelineStyle.getPropertyValue('-o-transform') ||
                timelineStyle.getPropertyValue('transform');

            var translateValue = 0;
            if (timelineTranslate.indexOf('(') >= 0) {
                timelineTranslate = timelineTranslate.split('(')[1];
                timelineTranslate = timelineTranslate.split(')')[0];
                timelineTranslate = timelineTranslate.split(',');
                translateValue = timelineTranslate[4];
            }

            return Number(translateValue);
        }

        function setTransformValue(element, property, value) {
            element.style['-webkit-transform'] = property + '(' + value + ')';
            element.style['-moz-transform'] = property + '(' + value + ')';
            element.style['-ms-transform'] = property + '(' + value + ')';
            element.style['-o-transform'] = property + '(' + value + ')';
            element.style.transform = property + '(' + value + ')';
        }

        function parseDate(events) {
            var dateArrays = [];

            events.forEach((event) => {
                var dateComp = event.getAttribute('data-date').split('/'),
                    newDate = new Date(dateComp[2], dateComp[1]-1, dateComp[0]);
                dateArrays.push(newDate);
            });

            return dateArrays;
        }

        function parseDate2(events) {
            var dateArrays = [];
            events.forEach((event) => {
                var singleDate = event,
                    dateComp = singleDate.getAttribute('data-date').split('T');

                if (dateComp.length > 1) {
                    var dayComp = dateComp[0].split('/'),
                        timeComp = dateComp[1].split(':');
                }
                else if (dateComp[0].indexOf(':') >=0 ) {
                    var dayComp = ["2000", "0", "0"],
                        timeComp = dateComp[0].split(':');
                }
                else {
                    var dayComp = dateComp[0].split('/'),
                        timeComp = ["0", "0"];
                }
                var newDate = new Date(dayComp[2], dayComp[1]-1, dayComp[0], timeComp[0], timeComp[1]);
                dateArrays.push(newDate);
            });
            return dateArrays;
        }

        function daydiff(first, second) {
            return Math.round((second-first));
        }

        function minLapse(dates) {
            var dateDistances = [];
            for (var i = 1; i < dates.length; i++) {
                var distance = daydiff(dates[i-1], dates[i]);
                dateDistances.push(distance);
            }
            return Math.min.apply(null, dateDistances);
        }

        function elementInViewport(el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }
    }
}

export default new Timeline();
