const NAV_SPACING_TOP = 4;
const NAV_SPACING_BOTTOM = 20;

class NavHeight {
    constructor() {
        this.calulate();

        // Listen to the resize event
        window.addEventListener('resize', () => {
            this.calulate();
        });

        // Listen to the scroll event
        window.addEventListener('scroll', () => {
            this.calulate();
        });
    }
    calulate() {
        let header = document.getElementById('mn-hdr');
        let headerSubnav = document.getElementById('mn-hdr-subnav');
        let headerSubnavStyle = window.getComputedStyle(headerSubnav);
        let offset = 0;

        offset = header.getBoundingClientRect().bottom;

        // Test if subnav is visible
        if (headerSubnavStyle.display !== 'none') {
            // Include header subnav element
            // Get margin top for subnav
            let headerSubnavCs = getComputedStyle(headerSubnav);
            let headerSubnavMt = parseInt(headerSubnavCs.marginTop);

            offset += headerSubnav.offsetHeight + headerSubnavMt;
        }

        let nh = window.innerHeight - offset - NAV_SPACING_TOP - NAV_SPACING_BOTTOM;

        // Then we set the value in the --nh custom property to the root of the document
        document.documentElement.style.setProperty('--nh', `${nh}px`);
    }
}

export default new NavHeight();
