class Sharer {
    constructor() {
    }
    init() {
        const elems = document.querySelectorAll('[data-sharer]');

        for (let i = 0; i < elems.length; i++) {
            elems[i].addEventListener('click', () => {
                this.share(elems[i]);
            });
        }
    }

    /**
     * @event share
     * @description Main share event. Will pop a window or redirect to a link
     * based on the data-sharer attribute.
     */
    share(el) {
        let sharer = el.getAttribute('data-sharer').toLowerCase();
        let sharers = {
            facebook: {
                shareUrl: 'https://www.facebook.com/sharer/sharer.php',
                params: {
                    u: el.getAttribute('data-url'),
                    hashtag: el.getAttribute('data-hashtag'),
                    quote: el.getAttribute('data-quote'),
                },
            },
            linkedin: {
                shareUrl: 'https://www.linkedin.com/shareArticle',
                params: {
                    url: el.getAttribute('data-url'),
                    mini: true,
                },
            },
            twitter: {
                shareUrl: 'https://twitter.com/intent/tweet/',
                params: {
                    text: el.getAttribute('data-title'),
                    url: el.getAttribute('data-url'),
                    hashtags: el.getAttribute('data-hashtags'),
                    via: el.getAttribute('data-via'),
                },
            },
            email: {
                shareUrl: 'mailto:' + el.getAttribute('data-to'),
                params: {
                    subject: el.getAttribute('data-subject'),
                    body: el.getAttribute('data-title') + '\n' + el.getAttribute('data-url'),
                },
            },
            whatsapp: {
                shareUrl: el.getAttribute('data-web') === 'true' ? 'https://web.whatsapp.com/send' : 'https://wa.me/',
                params: {
                    phone: el.getAttribute('data-to'),
                    text: el.getAttribute('data-title') + ' ' + el.getAttribute('data-url'),
                },
            },
            telegram: {
                shareUrl: 'https://t.me/share',
                params: {
                    text: el.getAttribute('data-title'),
                    url: el.getAttribute('data-url'),
                },
            },
            viber: {
                shareUrl: 'viber://forward',
                params: {
                    text: el.getAttribute('data-title') + ' ' + el.getAttribute('data-url'),
                },
            },
            line: {
                shareUrl:
                    'http://line.me/R/msg/text/?' + encodeURIComponent(el.getAttribute('data-title') + ' ' + el.getAttribute('data-url')),
            },
            pinterest: {
                shareUrl: 'https://www.pinterest.com/pin/create/button/',
                params: {
                    url: el.getAttribute('data-url'),
                    media: el.getAttribute('data-image'),
                    description: el.getAttribute('data-description'),
                },
            },
            tumblr: {
                shareUrl: 'http://tumblr.com/widgets/share/tool',
                params: {
                    canonicalUrl: el.getAttribute('data-url'),
                    content: el.getAttribute('data-url'),
                    posttype: 'link',
                    title: el.getAttribute('data-title'),
                    caption: el.getAttribute('data-caption'),
                    tags: el.getAttribute('data-tags'),
                },
            },
            hackernews: {
                shareUrl: 'https://news.ycombinator.com/submitlink',
                params: {
                    u: el.getAttribute('data-url'),
                    t: el.getAttribute('data-title'),
                },
            },
            reddit: {
                shareUrl: 'https://www.reddit.com/submit',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title')
                },
            },
            vk: {
                shareUrl: 'http://vk.com/share.php',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                    description: el.getAttribute('data-caption'),
                    image: el.getAttribute('data-image'),
                },
            },
            xing: {
                shareUrl: 'https://www.xing.com/social/share/spi',
                params: {
                    url: el.getAttribute('data-url'),
                },
            },
            buffer: {
                shareUrl: 'https://buffer.com/add',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                    via: el.getAttribute('data-via'),
                    picture: el.getAttribute('data-picture'),
                },
            },
            instapaper: {
                shareUrl: 'http://www.instapaper.com/edit',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                    description: el.getAttribute('data-description'),
                },
            },
            pocket: {
                shareUrl: 'https://getpocket.com/save',
                params: {
                    url: el.getAttribute('data-url'),
                },
            },
            mashable: {
                shareUrl: 'https://mashable.com/submit',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                },
            },
            mix: {
                shareUrl: 'https://mix.com/add',
                params: {
                    url: el.getAttribute('data-url'),
                },
            },
            flipboard: {
                shareUrl: 'https://share.flipboard.com/bookmarklet/popout',
                params: {
                    v: 2,
                    title: el.getAttribute('data-title'),
                    url: el.getAttribute('data-url'),
                    t: Date.now(),
                },
            },
            weibo: {
                shareUrl: 'http://service.weibo.com/share/share.php',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                    pic: el.getAttribute('data-image'),
                    appkey: el.getAttribute('data-appkey'),
                    ralateUid: el.getAttribute('data-ralateuid'),
                    language: 'zh_cn',
                },
            },
            blogger: {
                shareUrl: 'https://www.blogger.com/blog-this.g',
                params: {
                    u: el.getAttribute('data-url'),
                    n: el.getAttribute('data-title'),
                    t: el.getAttribute('data-description'),
                },
            },
            baidu: {
                shareUrl: 'http://cang.baidu.com/do/add',
                params: {
                    it: el.getAttribute('data-title'),
                    iu: el.getAttribute('data-url'),
                },
            },
            douban: {
                shareUrl: 'https://www.douban.com/share/service',
                params: {
                    name: el.getAttribute('data-name'),
                    href: el.getAttribute('data-url'),
                    image: el.getAttribute('data-image'),
                    comment: el.getAttribute('data-description'),
                },
            },
            okru: {
                shareUrl: 'https://connect.ok.ru/dk',
                params: {
                    'st.cmd': 'WidgetSharePreview',
                    'st.shareUrl': el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                },
            },
            mailru: {
                shareUrl: 'http://connect.mail.ru/share',
                params: {
                    share_url: el.getAttribute('data-url'),
                    linkname: el.getAttribute('data-title'),
                    linknote: el.getAttribute('data-description'),
                    type: 'page',
                },
            },
            evernote: {
                shareUrl: 'https://www.evernote.com/clip.action',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                },
            },
            skype: {
                shareUrl: 'https://web.skype.com/share',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                },
            },
            delicious: {
                shareUrl: 'https://del.icio.us/post',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                },
            },
            sms: {
                shareUrl: 'sms://',
                params: {
                    body: el.getAttribute('data-body'),
                },
            },
            trello: {
                shareUrl: 'https://trello.com/add-card',
                params: {
                    url: el.getAttribute('data-url'),
                    name: el.getAttribute('data-title'),
                    desc: el.getAttribute('data-description'),
                    mode: 'popup',
                },
            },
            messenger: {
                shareUrl: 'fb-messenger://share',
                params: {
                    link: el.getAttribute('data-url'),
                },
            },
            odnoklassniki: {
                shareUrl: 'https://connect.ok.ru/dk',
                params: {
                    st: {
                        cmd: 'WidgetSharePreview',
                        deprecated: 1,
                        shareUrl: el.getAttribute('data-url'),
                    },
                },
            },
            meneame: {
                shareUrl: 'https://www.meneame.net/submit',
                params: {
                    url: el.getAttribute('data-url'),
                },
            },
            diaspora: {
                shareUrl: 'https://share.diasporafoundation.org',
                params: {
                    title: el.getAttribute('data-title'),
                    url: el.getAttribute('data-url'),
                },
            },
            googlebookmarks: {
                shareUrl: 'https://www.google.com/bookmarks/mark',
                params: {
                    op: 'edit',
                    bkmk: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                },
            },
            qzone: {
                shareUrl: 'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey',
                params: {
                    url: el.getAttribute('data-url'),
                },
            },
            refind: {
                shareUrl: 'https://refind.com',
                params: {
                    url: el.getAttribute('data-url'),
                },
            },
            surfingbird: {
                shareUrl: 'https://surfingbird.ru/share',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                    description: el.getAttribute('data-description'),
                },
            },
            yahoomail: {
                shareUrl: 'http://compose.mail.yahoo.com',
                params: {
                    to: el.getAttribute('data-to'),
                    subject: el.getAttribute('data-subject'),
                    body: el.getAttribute('data-body'),
                },
            },
            wordpress: {
                shareUrl: 'https://wordpress.com/wp-admin/press-this.php',
                params: {
                    u: el.getAttribute('data-url'),
                    t: el.getAttribute('data-title'),
                    s: el.getAttribute('data-title'),
                },
            },
            amazon: {
                shareUrl: 'https://www.amazon.com/gp/wishlist/static-add',
                params: {
                    u: el.getAttribute('data-url'),
                    t: el.getAttribute('data-title'),
                },
            },
            pinboard: {
                shareUrl: 'https://pinboard.in/add',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                    description: el.getAttribute('data-description'),
                },
            },
            threema: {
                shareUrl: 'threema://compose',
                params: {
                    text: el.getAttribute('data-text'),
                    id: el.getAttribute('data-id'),
                },
            },
            kakaostory: {
                shareUrl: 'https://story.kakao.com/share',
                params: {
                    url: el.getAttribute('data-url'),
                },
            },
            yummly: {
                shareUrl: 'http://www.yummly.com/urb/verify',
                params: {
                    url: el.getAttribute('data-url'),
                    title: el.getAttribute('data-title'),
                    yumtype: 'button',
                },
            }
        };

        let s = sharers[sharer];

        // custom popups sizes
        if (s) {
            s.width = el.getAttribute('data-width');
            s.height = el.getAttribute('data-height');
        }

        return s !== undefined ? this.urlSharer(s, el) : false;
    }

    /**
     * @event urlSharer
     * @param {Object} sharer
     * @param {element} el
     */
    urlSharer(sharer, el) {
        let p = sharer.params || {},
            keys = Object.keys(p),
            str = keys.length > 0 ? '?' : '';

        for (let i = 0; i < keys.length; i++) {
            if (str !== '?') {
                str += '&';
            }
            if (p[keys[i]]) {
                str += keys[i] + '=' + encodeURIComponent(p[keys[i]]);
            }
        }
        sharer.shareUrl += str;

        let isLink = el.getAttribute('link') === 'true';
        let isBlank = el.getAttribute('blank') === 'true';

        if (isLink) {
            if (isBlank) {
                window.open(sharer.shareUrl, '_blank');
            } else {
                window.location.href = sharer.shareUrl;
            }
        } else {
            console.log(sharer.shareUrl);
            // defaults to popup if no data-link is provided
            let popWidth = sharer.width || 600,
                popHeight = sharer.height || 480,
                left = window.innerWidth / 2 - popWidth / 2 + window.screenX,
                top = window.innerHeight / 2 - popHeight / 2 + window.screenY,
                popParams = 'scrollbars=no, width=' + popWidth + ', height=' + popHeight + ', top=' + top + ', left=' + left,
                newWindow = window.open(sharer.shareUrl, '', popParams);

            if (window.focus) {
                newWindow.focus();
            }
        }
    }
}

export default new Sharer();
