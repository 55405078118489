class CookieMessage {
    constructor() {
        // Output message
        if (config.showCookieMessage) {
            this.addCookieMessage(config.cookieMessage);
        }
    }

    addCookieMessage(message) {
        if (document.cookie.indexOf('cookieMessageShown=true') === -1) {
            // Add the cookie message to the DOM
            const container = document.createElement('div');
            container.id = 'cookie-message';
            container.className = 'fixed bottom-5 left-0 w-full z-80';

            container.innerHTML = `
                    <div class="container">
                        <div class="bg-cream border-2 border-teal-dark p-5 rounded-2xl">
                            <div class="flex gap-7 justify-between items-center">
                                <div class="content">
                                    ${message}
                                </div>
                                <button id="cookie-message-confirm" class="font-semibold ">Got it</button>
                            </div>
                        </div>
                    </div>
                `;
            document.body.appendChild(container);

            // Set cookie
            this.setCookie();

            // Add event listener to remove the cookie message and set the cookie
            const confirmBtn = document.getElementById('cookie-message-confirm');
            confirmBtn.addEventListener('click', () => {
                container.remove();
            });
        }
    }
    setCookie() {
        const cookieExpiry = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString(); // Set cookie expiry to 1 year from now
        document.cookie = 'cookieMessageShown=true; expires=' + cookieExpiry + '; path=/'; // Set the cookie to expire in 1 year and apply to all paths
    }
}

export default new CookieMessage();
