// Imports
import Splide from '@splidejs/splide';
class MaterialCarousel {
    constructor() {
        this.carousels = {};

        const carouselEls = document.querySelectorAll('.material-carousel');

        carouselEls.forEach((carousel, i) => {
            const classNames = carousel.getAttribute('data-background-classes').split(',');

            this.carousels[i] = new Splide(carousel, {
                gap: 0,
                padding: 0,
                perMove: 1,
                perPage: 1,
                type: 'loop',
            });

            this.carousels[i].on('move', (newIndex) => {
                const newClass = classNames[newIndex % classNames.length];
                carousel.classList.remove(...classNames);
                carousel.classList.add(newClass);
            });

            // Mount the carousel
            this.carousels[i].mount();

            // Pass the background classes to memory
            this.carousels[i].backgroundClasses = classNames;

            // Set initial background class based on current slide
            const currentIndex = this.carousels[i].index;
            const initialClass = classNames[currentIndex % classNames.length];
            carousel.classList.add(initialClass);
        });
    }
}

export default new MaterialCarousel();
