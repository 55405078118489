import env from '@js/utils/env';
import gsap from 'gsap';

const getAttributeValue = (a, d) => {
    const value = parseFloat(a.trim());
    return Number.isNaN(value) ? d : value;
};

class Switchers {
    constructor() {
        // Uncomment to start on call
        //this.start();
    }

    init(el, debug) {
        if (el.switchersInited) {
            return;
        }

        const widthStatic = el.classList.contains('_static-width');
        const fixedHeight = el.classList.contains('_fixed-height');

        // Data attribute values
        const attribute = el.getAttribute('data-switch').split(',');
        const duration = getAttributeValue(attribute[0], 0.5);
        const delay = getAttributeValue(attribute[1], 1);
        const easing = attribute[2]?.trim() ?? '';
        const fadeEasing = attribute[3]?.trim() ?? '';
        const delayAppear = getAttributeValue(attribute[4], 0);
        const distance = getAttributeValue(attribute[5], 100);
        const maxRepeats = getAttributeValue(attribute[6], -1);

        if (debug) {
            console.log(el, duration, delay, easing);
        }

        const children = Array.from(el.children);

        let index = -1;
        let repeatsCounter = 0;
        let needHeight;
        let needWidth;
        let tallestChild = this.tallestChild(children);

        const next = (immediate = false) => {
            repeatsCounter++;

            if (maxRepeats > 0 && repeatsCounter > maxRepeats) {
                return;
            }

            index = (index + 1) % children.length;
            const showChild = children[index];

            const tweenTime = immediate ? 0 : duration;
            gsap.to(children, {
                y: `-${distance}%`,
                duration: env.reducedMotion ? 0 : tweenTime,
                ease: easing,
            });
            gsap.to(children, {
                alpha: 0,
                duration: env.reducedMotion ? 0 : tweenTime,
                ease: fadeEasing,
            });

            // Remove active class from all children
            children.forEach((c) => c.classList.remove('_active'));

            gsap.fromTo(
                showChild,
                {
                    x: '-50%',
                    y: `${distance}%`
                },
                {
                    x: '-50%',
                    y: '0%',
                    duration: env.reducedMotion ? 0 : tweenTime,
                    ease: easing,
                    delay: immediate ? 0 : delayAppear,
                }
            );
            gsap.fromTo(
                showChild,
                { alpha: 0 },
                {
                    alpha: 1,
                    duration: env.reducedMotion ? 0 : tweenTime,
                    ease: fadeEasing,
                    delay: immediate ? 0 : delayAppear,
                }
            );

            needHeight = showChild.offsetHeight;
            needWidth = showChild.offsetWidth;

            gsap.to(el, {
                duration: env.reducedMotion ? 0 : tweenTime / 2,
                delay: immediate ? 0 : env.reducedMotion ? 0 : delayAppear,
                height: fixedHeight ? tallestChild : needHeight,
                width: widthStatic ? '100%' : needWidth,
            });
            showChild.classList.add('_active');
            gsap.delayedCall(delay + (immediate ? 0 : delayAppear), next);
        };

        next(true);

        gsap.fromTo(el, { alpha: 0 }, { alpha: 1 });

        window.addEventListener('resize', () => {
            const activeChild = el.querySelector('._active');
            const updateHeight = activeChild.offsetHeight;
            const updateWidth = activeChild.offsetWidth;

            // Get the tallest child element's height
            const tallestChildHeight = this.tallestChild(children);

            gsap.set(el, {
                height: fixedHeight ? tallestChildHeight : updateHeight,
                width: widthStatic ? '100%' : updateWidth
            });
        });

        el.switchersInited = true;
    }

    start(debug) {
        const switchers = document.querySelectorAll('[data-switch]');
        switchers.forEach((el) => this.init(el, debug));
    }

    tallestChild(children) {
        return Math.max(...children.map(child => child.offsetHeight));
    }
}

export default new Switchers();
