// Imports
import { gsap } from "gsap";

class YoutubeEmbeds {
    constructor() {
        this.players = {};
    }

    init() {
        const embeds = document.querySelectorAll('.youtube-embed');

        embeds.forEach(embed => {
            const videoId = embed.getAttribute('data-video-id');

            this.players[videoId] = new YT.Player('v-'+videoId, {
                events: {
                    'onReady': (event) => this.onPlayerReady(event, videoId)
                }
            });
        });
    }


    onPlayerReady = (event, videoId) => {
        const buttons = document.querySelectorAll(`[data-youtube-play="${videoId}"]`);

        buttons.forEach(button => {
            button.addEventListener('click', (e) => {
                // Hide button
                gsap.to(button, {
                    duration: .25,
                    alpha: 0,
                    display: 'none'
                });

                event.target.playVideo();
            });
        });
    }

    onPlayerStateChange = (event, videoId) => {

    }

    loadAPI() {
        const apiScript = document.createElement('script');
        apiScript.src = "https://www.youtube.com/iframe_api";
        document.head.appendChild(apiScript);

        return new Promise((resolve) => {
            window.onYouTubeIframeAPIReady = () => {
                this.init();
                resolve();
            };
        });
    }
}

export default new YoutubeEmbeds();
