class Craft {
    // Helper for fetching a CSRF token
    getSessionInfo() {
        return fetch('/actions/users/session-info', {
            headers: {
                'Accept': 'application/json',
            },
        }).then(response => response.json());
    }
}

// Exporting getSessionInfo function only
export const getSessionInfo = new Craft().getSessionInfo.bind(new Craft());
