// Imports
import Carousels from '@js/modules/Carousels';
import Tracking from '@js/modules/Tracking';

class Salon {
    constructor() {

    }
    currentDay() {
        const dayOfWeek = new Date().getDay();
        const dayEls = document.querySelectorAll(`[data-salon-open-day="${dayOfWeek}"]`);

        dayEls.forEach(el => {
            el.classList.add('font-semibold');
        });
    }

    related() {
        const relatedAttr = 'data-salon-related';

        const relatedEl = document.querySelector(`[${relatedAttr}]`);
        if (relatedEl) {
            const relatedElEntry = relatedEl.getAttribute(relatedAttr);

            // Put URL together
            let url = `/salon-locator/related/v1/${relatedElEntry}`;

            console.log('Get data from ', url);

            // Get data from API
            fetch(url)
                .then(response => response.text())
                .then(data => {
                    relatedEl.innerHTML = data;

                    // Setup carousels
                    Carousels.setup();

                    // Lazy load images
                    window.lazyLoadInstance.update();

                    // Enable tracking
                    Tracking.setup();
                })
                .catch(error => console.error(error));
        }
    }
}

export default new Salon();
