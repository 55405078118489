// Imports
import env from '@js/utils/env';

class Characters {
    constructor() {
        /**
         * Character eye movement
         */
        if (!env.reducedMotion)
        {
            const characterEyes = document.querySelectorAll('.character-eyes');
            characterEyes.forEach(el => {
                document.addEventListener('mousemove', (e) => {
                    if (window.innerWidth > 768) {
                        this._movePupils(el, e);
                    }
                });
            });
        }
    }
    _movePupils(el, e) {
        let eyes = el.querySelectorAll('.eye');

        eyes.forEach(eye => {
            let eyeball = eye.querySelector('.eyeball');
            let pupil = eye.querySelector('.pupil');

            // Get center cx/cy and radius
            let pCenter = {
                x: +eyeball.getAttribute('cx'),
                y:+eyeball.getAttribute('cy')
            };
            let rEyeball = +eyeball.getAttribute('r');
            let rPupil = +pupil.getAttribute('r');

            // Translate cursor HTML DOM coordinates to SVG DOM units
            let pCursor = new DOMPoint(e.clientX, e.clientY);
            pCursor = pCursor.matrixTransform(el.getScreenCTM().inverse());

            // Get angle between cursor and eyeball center;
            let angle = (Math.atan2(pCursor.y - pCenter.y, pCursor.x - pCenter.x) * 180) / Math.PI;

            // Get distance between cursor and eyeball center
            let a = pCursor.x - pCenter.x;
            let b = pCursor.y - pCenter.y;
            let distance =  Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

            // Adjust pupil movement inside eyeball boundaries
            let offset = distance<rEyeball ? 1/rEyeball*distance : 1;
            let radiusOuter = (rEyeball-rPupil)*offset;

            let pMoved = {
                x: pCenter.x + Math.cos((angle * Math.PI) / 180) * radiusOuter,
                y: pCenter.y + Math.sin((angle * Math.PI) / 180) * radiusOuter
            };

            // Update attributes
            // X movement
            pupil.setAttribute('cx', pMoved.x);
            // Y movement
            // pupil.setAttribute('cy', pMoved.y);
        });
    }
}

export default new Characters();
